body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }
  
  #root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  body {
    font-family: 'Noto Sans', sans-serif;
  }